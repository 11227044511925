<template>
  <page-header-wrapper :title="this.$route.meta.title">
    <div>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleNew()">新建</a-button>
        <a-divider type="vertical" />
        <a-button type="primary" icon="reload" @click="$refs.table.refresh(true)">刷新</a-button>
      </div>

      <s-table ref="table" rowKey="id" size="default" :columns="columns" :data="loadData">
        <span slot="id" slot-scope="text, record">
          <template>
            <a @click="handleEdit(record)">
              <ellipsis :length="10" tooltip>{{ text }}</ellipsis>
            </a>
          </template>
        </span>
        <span slot="status" slot-scope="text">
          <template>
            <a-badge v-if="text == 'online'" dot status="processing" :text="'启用'" />
            <a-badge v-if="text == 'offline'" dot status="warning" :text="'停用'" />
          </template>
        </span>
        <span slot="prototype" slot-scope="text">
          <template>
            <a-badge v-if="text == 'business'" dot status="processing" :text="'商学院'" />
            <a-badge v-if="text == 'introduce'" dot status="processing" :text="'企业文化'" />
            <a-badge v-if="text == 'case'" dot status="processing" :text="'客户见证'" />
            <a-badge v-if="text == 'taisui'" dot status="processing" :text="'太岁'" />
            <a-badge v-if="text == 'class'" dot status="processing" :text="'养生课堂'" />
            <a-badge v-if="text == 'question'" dot status="processing" :text="'常见问题'" />
            <a-badge v-if="text == 'other'" dot status="processing" :text="'其他内容'" />
          </template>
        </span>
        <span slot="action" slot-scope="text, record">
          <template>
            <a-space>
              <a v-if="record.prototype == 'taisui' || record.prototype == 'class'" @click="handleList(record)">子栏目设置</a>
              <a v-if="record.status == 'online'" @click="handleEdit(record)">编辑</a>
              <a v-if="record.status == 'online'" @click="handleStatus(record, 'offline')">停用</a>
              <a v-if="record.status == 'offline'" @click="handleStatus(record, 'online')">启用</a>
              <a @click="handleDelete(record)">删除</a>
            </a-space>
          </template>
        </span>
      </s-table>
    </div>

    <a-modal title='子栏目设置' v-model='visible' :footer="null" width="800px">
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="initialDeliver()">新增</a-button>
        <a-divider type="vertical" />
        <a-button type="primary" icon="reload" @click="$refs.table1.refresh(true)">刷新</a-button>
      </div>
      <s-table ref='table1' rowKey='id' size='default' :columns='columns1' :data='loadData1'>
        <span slot="status" slot-scope="text">
          <template>
            <a-badge v-if="text == 'online'" dot status="processing" :text="'启用'" />
            <a-badge v-if="text == 'offline'" dot status="warning" :text="'停用'" />
          </template>
        </span>
        <span slot="prototype" slot-scope="text">
          <template>
            <a-badge v-if="text == 'business'" dot status="processing" :text="'商学院'" />
            <a-badge v-if="text == 'introduce'" dot status="processing" :text="'企业文化'" />
            <a-badge v-if="text == 'case'" dot status="processing" :text="'客户见证'" />
            <a-badge v-if="text == 'taisui'" dot status="processing" :text="'太岁'" />
            <a-badge v-if="text == 'class'" dot status="processing" :text="'养生课堂'" />
            <a-badge v-if="text == 'question'" dot status="processing" :text="'常见问题'" />
            <a-badge v-if="text == 'other'" dot status="processing" :text="'其他内容'" />
          </template>
        </span>
        <span slot="action" slot-scope="text, record">
          <template>
            <a-space>
              <a v-if="record.status == 'online'" @click="initialDeliver(record)">编辑</a>
              <a v-if="record.status == 'online'" @click="handleStatus(record, 'offline')">停用</a>
              <a v-if="record.status == 'offline'" @click="handleStatus(record, 'online')">启用</a>
              <a @click="handleDelete(record)">删除</a>
            </a-space>
          </template>
        </span>
      </s-table>
    </a-modal>``


    <a-modal :title='deliver.form.id ? "编辑子栏目" : "新增子栏目"' :destroyOnClose='true' :maskClosable='true' :visible='deliver.visible'
             @cancel='()=>this.deliver.visible=false'
             ok-text='提交' cancel-text='取消' @ok='handleDeliver' :confirmLoading='deliver.loading'>
      <a-form-model ref='form' :model='deliver.form' :rules='deliver.rules' :labelCol='{ span: 6 }' :wrapperCol='{ span: 18 }'>
        <a-form-model-item label='栏目名称' prop='name'>
          <a-input v-model='deliver.form.name' />
        </a-form-model-item>
        <a-form-model-item label='栏目状态' prop='status'>
          <a-radio-group v-model='deliver.form.status'>
            <a-radio value='online'>启用</a-radio>
            <a-radio value='offline'>停用</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label='排序优先级' prop='priority' help='数字越大优先级又高'>
          <a-input-number v-model='deliver.form.priority' />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from "@/components"
import { queryCampusSubjects, putCampusSubject, deleteCampusSubject } from "@/api/content"

const columns = [
  {
    title: "#",
    dataIndex: "id",
    scopedSlots: { customRender: "id" }
  },
  {
    title: "栏目名称",
    dataIndex: "name",
    scopedSlots: { customRender: "name" }
  },
  {
    title: "栏目状态",
    dataIndex: "status",
    scopedSlots: { customRender: "status" }
  },
  ,
  {
    title: "所属板块",
    dataIndex: "prototype",
    scopedSlots: { customRender: "prototype" }
  },
  {
    title: "排序优先级",
    dataIndex: "priority",
    scopedSlots: { customRender: "priority" }
  },
  {
    title: "操作",
    fixed: "right",
    dataIndex: "action",
    scopedSlots: { customRender: "action" }
  }
]

const columns1 = [
  {
    title: "栏目名称",
    dataIndex: "name",
    scopedSlots: { customRender: "name" }
  },
  {
    title: "栏目状态",
    dataIndex: "status",
    scopedSlots: { customRender: "status" }
  },
  ,
  {
    title: "所属栏目",
    dataIndex: "prototype",
    scopedSlots: { customRender: "prototype" }
  },
  {
    title: "排序优先级",
    dataIndex: "priority",
    scopedSlots: { customRender: "priority" }
  },
  {
    title: "操作",
    fixed: "right",
    dataIndex: "action",
    scopedSlots: { customRender: "action" }
  }
]
export default {
  name: "ContentSubjectList",
  components: {
    STable,
    Ellipsis
  },
  data() {
    return {
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      queryParam: {level: 0},
      loadData: parameter => {
        return queryCampusSubjects(Object.assign(parameter, this.queryParam)).then(datum => {
          return datum
        })
      },
      columns1,
      queryParam1: {level: 1},
      loadData1: parameter => {
        return queryCampusSubjects(Object.assign(parameter, this.queryParam1)).then(datum => {
          return datum
        })
      },
      visible: false,
      deliver: { 
        visible: false, 
        loading: false,
        form: {},
        rules: {
          name: [
            { required: true, message: '请填写栏目名称', trigger: 'change' },
            { min: 4, max: 6, message: '栏目名称字符数限定4～6个', trigger: 'change' }
          ],
          status: [
            { required: true, message: '请选择栏目状态', trigger: 'change' }
          ],
          priority: [
            { required: true, message: '请填写排序优先级', trigger: 'change' }
          ]
        } 
      }, 
    }
  },
  created() {},
  methods: {
    handleDeliver() {
      this.$refs.form.validate(result => {
        if (result) {
          this.deliver.loading = true;
          putCampusSubject(this.deliver.form).then(() => {
            this.$message.success('操作成功')
            this.deliver.visible = false;
            this.$refs.table1.refresh(true)
            this.deliver.loading = false;
          }).catch(() => {
            this.deliver.loading = false;
          })
        }
      })
    },
    initialDeliver(record) {
      this.deliver.visible = true;
      this.deliver.form = Object.assign({}, record || {}, this.queryParam1);
    },
    handleList(record) {
      this.queryParam1.parent = record.id;
      this.queryParam1.prototype = record.prototype;
      this.visible = true;
      if (this.$refs.table1) {
        this.$refs.table1.refresh(true)
      }
    },
    handleNew() {
      this.$router.push("/content/category/edit")
    },
    handleEdit(record) {
      this.$router.push({ path: "/content/category/edit", query: { id: record.id } })
    },
    handleStatus(record, status) {
      putCampusSubject(Object.assign({}, record, { status: status })).then(() => {
        this.$message.success("操作成功")
        if (this.$refs.table1) {
          this.$refs.table1.refresh(true)
        }
      })
    },
    handleDelete(record) {
      deleteCampusSubject({ id: record.id }).then(() => {
        this.$message.success("操作成功")
        this.$refs.table.refresh(true)
        if (this.$refs.table1) {
          this.$refs.table1.refresh(true)
        }
      })
    }
  }
}
</script>
